import React, {Fragment, useState} from 'react';

import { apiSavePreTestAnswers } from '../../../api/apiCommands';
import {QuestionFieldsetComponent} from "./questionFieldsetComponent";

import {ReactComponent as ReactChevronLefttSvg}  from '../../../assets/icon-left.svg';
import {ReactComponent as ReactChevronRighttSvg}  from '../../../assets/icon-right.svg';
import {CircularProgressbar} from "react-circular-progressbar";
import {useDispatch, useSelector} from "react-redux";
import {clearAnswer} from "../../../redux/preTestAnswersSlice";
import {useHistory} from "react-router-dom";

export const PreQuestionComponent = ({preTestQuestions, userStateToken, quizId, getQuizQuestions, clearPreTestQuestionState}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const preTestAnswers = useSelector(state => state.preTestAnswers.answers);
  const [error, setError] = useState('');

  const submitHandler = async (evt) => {
    evt.preventDefault();

    apiSavePreTestAnswers(userStateToken, quizId, preTestAnswers)
      .then((response) => {
        if (response.status && response.status === 'error') {
          setError(response.reason);

          setTimeout(() => {
            setError('');
          }, 3000);
        } else {
          dispatch(clearAnswer());
          clearPreTestQuestionState();
          getQuizQuestions();
        }
      })
      .catch((error) => {
        history.push('/');
      });
  }

  const nextQuestionHandler = () => {
    setCurrentQuestion(currentQuestion + 1);

    if (currentQuestion >= preTestQuestions.length) {
      setCurrentQuestion(preTestQuestions.length);
    }
  }

  const previousQuestionHandler = () => {
    setCurrentQuestion(currentQuestion - 1);

    if (currentQuestion <= 0) {
      setCurrentQuestion(0);
    }
  }

  const progressPercent = Math.floor((currentQuestion + 1) / preTestQuestions.length * 100);

  return (
    <>
      <div className="test__progress-box">
        <CircularProgressbar
          className="test__progress"
          value={progressPercent}
          text={(currentQuestion + 1) <= preTestQuestions.length ? `${currentQuestion + 1} / ${preTestQuestions.length}` : 'Complete'}
          strokeWidth={6}
          styles={{
            path: {
              stroke: `rgba(195, 54, 211, ${progressPercent / 100})`,
            },
            text: {
              fontSize: '16px',
            },
          }
          }
        />
      </div>

      <form className="test__pre-form" onSubmit={submitHandler}>
        {preTestQuestions.map((preTestQuestion, fieldIndex) => {
          return (
            <QuestionFieldsetComponent
              key={fieldIndex}
              currentQuestion={currentQuestion}
              fieldIndex={fieldIndex}
              question={preTestQuestion}
              nextQustionHandler={nextQuestionHandler}
              // changeUserAnswers={changeUserAnswers}
              // userAnswers={userAnswers}
              isStateQuestion={true}
            />
          )
        })}

        {currentQuestion === preTestQuestions.length &&
          <>
            <button className="test__pre-button button" type="submit"
                    disabled={currentQuestion !== preTestQuestions.length}>Complete the pre-test
            </button>
          </>
        }
      </form>

      {error && <div className="test__error-span">{error}</div>}

      <div className="test__switch-container">
        <button className="test__switch-button test__switch-button--prev" type="click"
                onClick={() => previousQuestionHandler()} disabled={currentQuestion === 0}>
          <ReactChevronLefttSvg className="test__switch-svg"/>
          Prev question
        </button>
        <button className="test__switch-button test__switch-button--next" type="click"
                onClick={() => nextQuestionHandler()} disabled={currentQuestion === preTestQuestions.length}>
          <ReactChevronRighttSvg className="test__switch-svg"/>
          Next question
        </button>
      </div>
    </>
  )
}