import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {apiGetStates} from '../api/apiCommands'

const initialState =  {
  list: [],
}

export const getStatesFromApi = createAsyncThunk(
  'stateList/getTestsFromApi',
  async (userToken) => {
    return apiGetStates(userToken)
  }
)

const stateSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    setStateList(state, action) {
      state.list = action.payload;
    },
  },

  extraReducers: {
    [getStatesFromApi.pending]: (state, action) => {
    },

    [getStatesFromApi.fulfilled]: (state, action) => {
      const response = action.payload;
      state.list = response.states;
    },

    [getStatesFromApi.rejected]: (state, action) => {
      state.list = [];
    },
  }
})

export default stateSlice.reducer
export const {
} = stateSlice.actions