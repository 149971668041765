import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiAuthorization } from '../api/apiCommands'

const initialState =  {
  userRole: null,
  expires: null,
  token: null,
  refreshToken: null,
  status: null,
  stateId: null
}

export const getUserDataFromApi = createAsyncThunk(
  'userData/getUserDataFromApi',
  async (form) => {
    return apiAuthorization(form)
  }
)

const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setNewState(state, action) {
      state.userRole = action.payload.role;
      state.expires = action.payload.expires;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refresh_token;
      state.stateId = action.payload.state_id;
    },

    changeStateId(state, action) {
      state.stateId = action.payload.state_id;
    },

    nullifyUserState(state) {
      state.userRole = null;
      state.expires = null;
      state.token = null;
      state.refreshToken = null;
      state.status = null;
      state.state_id = null;
    }
  },

  extraReducers: {
    [getUserDataFromApi.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getUserDataFromApi.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.code === 401) {
        state.status = response.message
      }

      else {
        state.userRole = action.payload.role
        state.expires = action.payload.expires
        state.token = action.payload.token
        state.refreshToken = action.payload.refresh_token
        state.stateId = action.payload.state_id
        state.status = 'success'
      }
    },

    [getUserDataFromApi.rejected]: (state, action) => {
      state.status = 'failed'
    },
  }
})

export default userSlice.reducer
export const {
  setNewState,
  changeStateId,
  nullifyUserState
} = userSlice.actions