import React, {Fragment, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {nullifyUserState} from '../../redux/userSlice';
import {getTestsFromApi} from '../../redux/testSlice';
import {TableComponent} from '../components/homePageComponents/tableComponent';
import {LoadingComponent} from '../components/general/loadingComponent';

import {ReactComponent as ReactLogoutSvg} from '../../assets/icon-logout.svg';
import logoImg from '../../assets/icon-logo.svg';
import {hideChoiceStateModalAction, showChoiceStateModalAction} from "../../redux/modalSlice";
import ForgotPasswordModal from "../components/ForgotPasswordModal/ForgotPasswordModal";
import ChoiceStateModal from "../components/ChoiceStateModal/ChoiceStateModal";

export const HomePage = () => {
  const dispatch = useDispatch();

  const {token, userRole, stateId} = useSelector(state => state.userData);
  const {activeTests, finishedTests, status:testsStatus, reloadStatus} = useSelector(state => state.testsData);

  const logoutHandler = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('testData');
    localStorage.removeItem('acknowledge');
    dispatch(nullifyUserState());
  }

  // getting lists of tests, adding them to the state

  useEffect(() => {
    if (reloadStatus) {
      dispatch(getTestsFromApi(token))
        .then((response) => {
          // console.log(response.payload.requests['remediation_caption'])
        })
        .catch((error) => {
          logoutHandler();
        })
    }

  }, [reloadStatus])

  useEffect(() => {
    if (! stateId) {
      dispatch(showChoiceStateModalAction());
    } else {
      dispatch(hideChoiceStateModalAction());
    }
  }, [stateId])

  return (
    <div className="homepage">

      <section className="homepage__section container">
        {testsStatus === 'loading' ?
          <LoadingComponent>
            <span>Please wait...</span>
          </LoadingComponent> :
          <>
            <header className="homepage__header">
              <img className="homepage__logo-image" loading="lazy" src={logoImg} width="330" height="auto"
                   alt="Information Consultants"/>
              {!reloadStatus && <span>Your test results are still being processed, please wait...</span>}
              <button className="homepage__button button" type="button" onClick={logoutHandler}>
                <ReactLogoutSvg className="homepage__button-svg"/>
                Log out
              </button>
            </header>

            { ! stateId && <ChoiceStateModal/>}

            <div className="homepage__active-quizzes">
              <h2>Active Tests</h2>
              {activeTests.length !== 0 ?
                <TableComponent
                  userRole={userRole}
                  tests={activeTests}
                  isFinishTable={false}
                  tableType={!reloadStatus ? "active" : null}
                /> :
                <span className="homepage__quizzes-span">No active tests</span>
              }
            </div>

            <div className="homepage__finished-quizzes">
              <h2>Finished Tests</h2>
              {finishedTests.length !== 0 ?
                <TableComponent
                  userRole={userRole}
                  tests={finishedTests}
                  isFinishTable={true}
                  tableType={"finish"}
                /> :
                <span className="homepage__quizzes-span">No finished tests</span>
              }
            </div>
          </>
        }
      </section>
    </div>
  );
}