import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiAuthorization } from '../api/apiCommands'

const initialState =  {
  answers: []
}

const preTestAnswerSlice = createSlice({
  name: 'preTestAnswers',
  initialState,
  reducers: {
    saveAnswer(state, action) {
      state.answers = state.answers.filter(
        (answer) => answer.questionId !== action.payload.questionId
      );

      state.answers.push(action.payload);
    },

    clearAnswer(state, action) {
      state.answers = initialState.answers;
    },
  },

  extraReducers: {
  }
})

export default preTestAnswerSlice.reducer
export const {
  saveAnswer,
  clearAnswer
} = preTestAnswerSlice.actions