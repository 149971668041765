import { combineReducers } from "redux";
import  userSlice  from "./userSlice";
import testsSlice from "./testSlice";
import modalsSlice from "./modalSlice";
import stateSlice from "./stateSlice";
import preTestAnswerSlice from "./preTestAnswersSlice";

const rootReducer = combineReducers({
  userData: userSlice,
  testsData: testsSlice,
  modals: modalsSlice,
  states: stateSlice,
  preTestAnswers: preTestAnswerSlice,
});

export default rootReducer;